import { useI18n } from 'vue-i18n'

import contextsData from '@shared/config/registeringContexts.json'

export default function useRegisteringContexts() {
  const { t } = useI18n()

  /**
   * Merge options in main context object
   *
   * @param {Object} element
   * @returns
   */
  function compact(element) {
    return {
      ...element,
    }
  }

  /**
   * Get context based on role
   *
   * @param role
   * @returns {Object}
   */
  function getRoleContext(role) {
    return enabledSummaryContexts.find((c) => c.roles.includes(role))
  }

  /**
   * Get other role in a pair
   *
   * @param role
   * @returns {string|null}
   */
  function getPairedRole(role) {
    if (!isPaired(role)) {
      return null
    }

    return getRoleContext(role).roles.filter((r) => ![role].includes(r))[0]
  }

  /**
   * Check if context is paired
   * based on role
   *
   * @param role
   * @returns {boolean}
   */
  function isPaired(role) {
    return getRoleContext(role).type === 'paired'
  }

  /**
   * Get role translated name
   *
   * @param role
   * @returns {string}
   */
  function getRoleName(role) {
    return t(`enums.App\\Enums\\UserRoleEnum.${role}`)
  }

  /**
   * User contexts
   */
  const contexts = contextsData.map((element) => compact(element))
    .filter((element) => element.enabled)

  /**
   * Enabled contexts
   * with fewer infos
   */
  const enabledSummaryContexts = contexts.map((c) => (
    {
      roles: Object.values(c.roles),
      type: c.type,
    }
  ))

  return {
    contexts,
    enabledSummaryContexts,
    getRoleContext,
    getPairedRole,
    getRoleName,
    isPaired,
  }
}
