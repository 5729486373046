<template>
  <div
    v-if="success"
  >
    <h2 class="font-bold mb-4 text-lg">
      {{ t('ttmt.auth.password_forgotten.success.title') }}
    </h2>

    <p class="mb-2">
      {{ t('ttmt.auth.password_forgotten.success.email_1', { email: values.credential }) }}
    </p>

    <p class="mb-3">
      {{ t('ttmt.auth.password_forgotten.success.email_2') }}
    </p>

    <div class="mb-3">
      <app-button
        emphasis="low"
        :label="t('ttmt.auth.password_forgotten.success.send_again')"
        whitespace-classes="whitespace-normal"
        :loading="formSubmitting"
        :disabled="formSubmitting || !sendCooldownEnded"
        display-classes="flex"
        class="mx-auto"
        width="fit"
        @click="handleSubmit"
      />

      <p
        v-if="!sendCooldownEnded"
        class="text-xs text-center"
      >
        {{ t('ttmt.auth.password_forgotten.success.wait_before', { seconds: sendCooldown }) }}
      </p>
    </div>

    <app-button
      emphasis="low"
      :label="t('ttmt.auth.password_forgotten.success.correct_credential')"
      whitespace-classes="whitespace-normal"
      display-classes="flex"
      class="mb-3 mx-auto"
      width="fit"
      @click="resetForm"
    />
  </div>

  <!-- v-show is used to keep the form active, and do not lose the values -->
  <form
    v-show="!success"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <p class="text-xs mb-3">
      {{ t('ttmt.auth.password_forgotten.intro') }}
    </p>

    <form-group
      rules="required|credential"
      name="credential"
      type="email"
      :label="false"
      label-as-placeholder
      :initial-value="credential"
      :form-control-props="{
        iconProps: { library: 'coolicon', name: 'mail' },
      }"
    />

    <app-button
      feature="confirm"
      type="submit"
      width="full"
      :disabled="formSubmitting"
      :loading="formSubmitting"
      padding="py-3 px-5"
      display-classes="flex"
      class="min-w-fit max-w-8/10 mx-auto mt-8"
    />
  </form>

  <router-link
    :to="{ name: 'login' }"
    class="app-link block w-fit mx-auto mt-8"
  >
    {{ t('ttmt.auth.messages.login') }}
  </router-link>

  <router-link
    :to="{ name: 'register' }"
    class="app-link block w-fit mx-auto mt-3"
  >
    {{ t('ttmt.auth.messages.register') }}
  </router-link>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useForm as useVVForm } from 'vee-validate'
import { useRoute } from 'vue-router'

import { sendPasswordResetLink } from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const { t } = useI18n()

const {
  setErrors,
  values,
} = useVVForm()

const route = useRoute()
const credential = route.query.credential

const formSubmitting = ref(false)
const success = ref(false)

function handleSubmit() {
  if (!formSubmitting.value) {
    formSubmitting.value = true

    sendPasswordResetLink({
      data: {
        attributes: values,
      },
    })
      .then(() => {
        success.value = true
        triggerSendCooldown()
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          setErrors({ ...e.response.data.errors })
        }
        resetForm()
      })
      .finally(() => {
        formSubmitting.value = false
      })
  }
}

// Reset states
function resetForm() {
  success.value = false
}

// ---------- SEND COOLDOWN ----------

const sendCooldown = ref(0)

function triggerSendCooldown() {
  sendCooldown.value = 60
}

const sendCooldownEnded = computed(() => (
  sendCooldown.value <= 0
))

setInterval(() => {
  if (sendCooldown.value > 0) {
    sendCooldown.value -= 1
  }
}, 1000)
</script>
