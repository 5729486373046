<template>
  <registration-success
    v-if="success"
    :context="context"
  />

  <!--
    use v-show and not v-if,
    so form values are not lost when step change
  -->
  <form
    v-show="!success"
    novalidate
    @submit.prevent="onSubmit"
  >
    <form-global-errors
      class="mb-5"
    />

    <registration-step-context
      v-show="step === 1"
      @next="next"
    />

    <registration-step-manager
      v-show="step === 2"
      @next="next"
      @back="back"
    />

    <registration-step-employee
      v-show="step === 3"
      @next="next"
      @back="back"
    />

    <registration-step-verifications
      v-show="step === 4"
      @back="back"
    />

    <app-button
      v-if="step === 4"
      type="submit"
      width="full"
      :disabled="formSubmitting || invalid"
      :loading="formSubmitting"
      :label="t('ttmt.auth.extranet_register.form.validate_registration')"
      padding="py-3 px-5"
      display-classes="flex"
      class="min-w-fit max-w-8/10 mx-auto mt-8"
    />
  </form>

  <p
    class="font-bold block mx-auto w-fit mt-8"
  >
    {{ t('ttmt.auth.messages.have_account') }}

    <router-link
      :to="{ name: 'login' }"
      class="app-link"
    >
      {{ t('ttmt.auth.messages.login') }}
    </router-link>
  </p>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { register } from '@shared/http/api'
import useLocale from '@shared/hooks/locale'
import useForm from '@shared/hooks/form/form'
import RegistrationStepContext from '@extranet/components/auth/registration/RegistrationStepContext.vue'
import RegistrationStepManager from '@extranet/components/auth/registration/RegistrationStepManager.vue'
import RegistrationStepEmployee from '@extranet/components/auth/registration/RegistrationStepEmployee.vue'
import RegistrationStepVerifications from '@extranet/components/auth/registration/RegistrationStepVerifications.vue'
import RegistrationSuccess from '@extranet/components/auth/registration/RegistrationSuccess.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import AppButton from '@shared/components/ui/AppButton.vue'

const { t } = useI18n()
const { shortLocale } = useLocale()

// ========== FORM ==========

const formSubmitting = ref(false)
const formErrors = ref({})
const step = ref(1)
const success = ref(false)
const context = ref({})

const {
  handleSubmit,
  invalid,
  form,
} = useForm({ additionalErrors: formErrors })

async function onSubmit() {
  const fieldsToSubmit = await handleSubmit()

  if (fieldsToSubmit && !formSubmitting.value) {
    formSubmitting.value = true

    // Set the submitted locale
    fieldsToSubmit.locale = shortLocale.value

    const params = {
      data: {
        type: 'users',
        attributes: {
          ...fieldsToSubmit,
          ...{
            context: fieldsToSubmit?.context?.name,
          },
        },
      },
    }

    register(params)
      .then(() => {
        context.value = fieldsToSubmit?.context
        success.value = true
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        formSubmitting.value = false
      })
  }
}

// ========== HANDLERS ==========
// Validate context step

function back() {
  if (form.values.context.name === 'manager' && step.value === 4) {
    step.value = 2
  } else {
    step.value -= 1
  }
}

function next() {
  if (form.values.context.name === 'manager' && step.value === 2) {
    step.value = 4
  } else {
    step.value += 1
  }
}
</script>
