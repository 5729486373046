<template>
  <div>
    <p
      class="text-center font-bold"
    >
      {{ formTitle }}
    </p>
    <div
      class="mt-8"
    >
      <div class="flex flex-col md:flex-row">
        <form-group
          :rules="nullableForManager"
          name="employee.last_name"
          :label="t('validation.attributes.last_name')"
          type="text"
          class="flex-1 mr-0 md:mr-2"
        />

        <form-group
          :rules="nullableForManager"
          name="employee.first_name"
          :label="t('validation.attributes.first_name')"
          type="text"
          class="flex-1"
        />
      </div>

      <form-group
        :rules="`${nullableForManager}|email`"
        name="employee.email"
        type="email"
        :hint="t('ttmt.form.hints.auth.register.employee.not_duplicate_email') + t('enums.App\\Enums\\UserRoleEnum.' + form.values?.context?.roles?.manager)"
        :form-control-props="{
          iconProps: { library: 'coolicon', name: 'mail' },
        }"
      />

      <!-- Password confirmation -->
      <form-group
        :rules="`${nullableForManager}|confirmed:@employee.password`"
        name="employee.password"
        type="password"
        hide-errors
        :form-control-props="{
          autocomplete: 'new-password',
          iconProps: { library: 'local', name: 'lock' },
        }"
      >
        <template #hint>
          <form-password-hint-list
            :password="form.values.employee?.password"
          />
        </template>
      </form-group>

      <!-- Password confirmation -->
      <form-group
        :rules="`${nullableForManager}|confirmed:@employee.password`"
        name="employee.password_confirmation"
        :hint="t('ttmt.form.hints.auth.register.employee.not_duplicate_password') + t('enums.App\\Enums\\UserRoleEnum.' + form.values?.context?.roles?.manager)"
        type="password"
        :form-control-props="{
          autocomplete: 'new-password',
          iconProps: { library: 'local', name: 'lock' },
        }"
      />
    </div>
    <div
      class="flex"
    >
      <app-button
        padding="py-3 px-8"
        type="button"
        display-classes="flex"
        class="min-w-fit max-w-8/10 mx-auto mt-8"
        :label="t('ttmt.common.actions.return')"
        @click="back"
      />
      <app-button
        padding="py-3 px-8"
        type="button"
        :disabled="!isFormFilled"
        display-classes="flex"
        class="min-w-fit max-w-8/10 mx-auto mt-8"
        :label="t('ttmt.common.actions.next')"
        @click="next"
      />
    </div>
  </div>
</template>

<script setup>
import {
  capitalize,
} from 'lodash'
import {
  computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import useFormFields from '@shared/hooks/form/formFields'

import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormPasswordHintList from '@shared/components/form/password_hint/FormPasswordHintList.vue'

const emits = defineEmits([
  'next',
  'back',
])

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()
const { form } = useFormFields(props)

const isFormFilled = computed(() => (
  form.value.values.employee?.last_name
    && form.value.values.employee?.first_name
    && form.value.values.employee?.email
    && form.value.values.employee?.password
    && form.value.values.employee?.password_confirmation
))

// ========== COMPUTED ==========

const nullableForManager = computed(() => (form.values?.context?.name === 'manager' ? 'required' : ''))

const formTitle = computed(() => capitalize(`${t('ttmt.auth.extranet_register.form.form_title')} ${t(`enums.App\\Enums\\UserRoleEnum.${form.value.values?.context?.roles?.employee}`)}`))

// ========== STEPS ==========
function next() {
  emits('next')
}

function back() {
  emits('back')
}
</script>
