<template>
  <p class="font-bold mb-4">
    {{ t('ttmt.auth.register.success.paired.email_1') }} :
  </p>

  <ul class="ml-12 list-disc mb-4">
    <li>{{ form.values.email }}</li>
    <li>{{ form.values.employee.email }}</li>
  </ul>

  <p class="mb-4">
    {{ t('ttmt.auth.register.success.paired.email_2') }} :
  </p>
</template>

<script setup>
import { inject } from 'vue'
import { useI18n } from 'vue-i18n'

const form = inject('form')

const { t } = useI18n()

defineProps({
  // Registering context object.
  context: {
    type: Object,
    default: null,
  },
})
</script>
