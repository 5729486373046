<template>
  <div>
    <p
      class="text-center font-bold"
    >
      {{ t('ttmt.auth.extranet_register.form.choose_establishment') }}
    </p>

    <app-card-item
      v-for="(context, i) in localContexts"
      :key="i"
      class="p-3 my-4 cursor-pointer border-4 border-white"
      :class="isCurrentContext(context.name) ? 'border-theme-500' : ''"
      @click="selectContext(context)"
    >
      <div class="flex">
        <div class="flex mx-2 text-center items-center">
          <app-icon
            library="coolicon"
            :name="isCurrentContext(context.name) ? 'checkbox_square' : 'checkbox'"
            size="text-2xl"
            :class="isCurrentContext(context.name) ? 'text-theme-500' : ''"
          />
        </div>

        <div>
          <p class="font-bold text-2xl">
            {{ t('enums.App\\Enums\\ContextsEnum.' + context.name) }}
          </p>

          <p>
            {{ getAttributeTranslation(context.description) }}
          </p>
        </div>
      </div>
    </app-card-item>

    <form-group
      type="hidden"
      name="context"
    />

    <app-button
      padding="py-3 px-5"
      type="button"
      width="full"
      :disabled="!isContextSelected"
      display-classes="flex"
      class="min-w-fit max-w-8/10 mx-auto"
      :label="t('ttmt.common.actions.next')"
      @click="next"
    />
  </div>
</template>

<script setup>
import { inject, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { isNil } from 'lodash'

import useLocale from '@shared/hooks/locale'
import useRegisteringContexts from '@shared/hooks/registeringContexts'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const form = inject('form')

const emits = defineEmits([
  'next',
])

const { t } = useI18n()
const { getAttributeTranslation } = useLocale()

// ========== CONTEXT ==========

const { contexts } = useRegisteringContexts()

const localContexts = ref(contexts)

const selectedContext = computed(() => (
  form.value.values.context?.name
))

// Check if selectedContext is current context.
const isCurrentContext = ($context) => selectedContext.value === $context

const isContextSelected = computed(() => (
  !isNil(selectedContext.value)
))

function selectContext(context) {
  form.value.setFieldValue('context', context)
}

// ========== STEPS ==========

function next() {
  emits('next')
}
</script>
