<template>
  <div>
    <p
      class="text-center font-bold"
    >
      {{ formTitle }}
    </p>

    <!-- First and Last Names -->
    <div
      class="mt-8"
    >
      <div class="flex flex-col md:flex-row">
        <form-group
          rules="required"
          name="last_name"
          type="text"
          class="flex-1 mr-0 md:mr-2"
        />

        <form-group
          rules="required"
          name="first_name"
          :label="t('validation.attributes.first_name')"
          type="text"
          class="flex-1"
        />
      </div>
    </div>

    <!-- Countries -->
    <form-group
      rules="required"
      name="address.country"
      type="select"
      :form-control-props="{
        options: countries,
      }"
    >
      <template #control-vue-multiselect-option="option">
        <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
      </template>
      <template #control-vue-multiselect-singlelabel="option">
        <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
      </template>
    </form-group>

    <!-- Email -->
    <form-group
      rules="required"
      name="email"
      type="email"
      :hint="t('ttmt.form.hints.auth.register.email')"
      :form-control-props="{
        iconProps: { library: 'coolicon', name: 'mail' },
      }"
    />

    <!-- Password -->
    <form-group
      rules="required"
      name="password"
      type="password"
      hide-errors
      :form-control-props="{
        autocomplete: 'new-password',
        iconProps: { library: 'local', name: 'lock' },
      }"
    >
      <template #hint>
        <form-password-hint-list
          :password="form.values.password"
        />
      </template>
    </form-group>

    <!-- Password confirmation -->
    <form-group
      rules="confirmed:@password|required"
      name="password_confirmation"
      type="password"
      :form-control-props="{
        autocomplete: 'new-password',
        iconProps: { library: 'local', name: 'lock' },
      }"
    />

    <!-- Steps buttons -->
    <div
      class="flex"
    >
      <app-button
        padding="py-3 px-8"
        type="button"
        display-classes="flex"
        class="min-w-fit max-w-8/10 mx-auto mt-8"
        :label="t('ttmt.common.actions.return')"
        @click="back"
      />
      <app-button
        padding="py-3 px-8"
        type="button"
        :disabled="!isFormFilled"
        display-classes="flex"
        class="min-w-fit max-w-8/10 mx-auto mt-8"
        :label="t('ttmt.common.actions.next')"
        @click="next"
      />
    </div>
  </div>
</template>

<script setup>
import { capitalize } from 'lodash'
import {
  computed,
} from 'vue'
import { useI18n } from 'vue-i18n'

import useFormFields from '@shared/hooks/form/formFields'
import useGeoloc from '@shared/hooks/geoloc'

import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormPasswordHintList from '@shared/components/form/password_hint/FormPasswordHintList.vue'

const emits = defineEmits([
  'next',
  'back',
])

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()

const { form } = useFormFields(props)
const { countries } = useGeoloc()

// ========== STEPS ==========

const isFormFilled = computed(() => (
  form.value.values.last_name
    && form.value.values.first_name
    && form.value.values.email
    && form.value.values.address?.country
    && form.value.values.password
    && form.value.values.password_confirmation
))

const formTitle = computed(() => capitalize(`${t('ttmt.auth.extranet_register.form.form_title')} ${t(`enums.App\\Enums\\UserRoleEnum.${form.value.values?.context?.roles?.manager}`)}`))

// ========== STEPS ==========

const next = () => emits('next')

const back = () => emits('back')
</script>
