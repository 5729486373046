<template>
  <paired-success-message
    v-if="isPairedContext"
    :context="context"
  />

  <alone-success-message
    v-else
    :context="context"
  />
</template>

<script setup>
import {
  onBeforeMount,
  ref,
} from 'vue'

import AloneSuccessMessage from '@extranet/components/auth/registration/success/AloneSuccessMessage.vue'
import PairedSuccessMessage from '@extranet/components/auth/registration/success/PairedSuccessMessage.vue'

const props = defineProps({
  // Context of registering
  context: {
    type: Object,
    default: null,
  },
})

const isPairedContext = ref(false)

onBeforeMount(() => {
  isPairedContext.value = (props.context.type === 'paired')
})
</script>
